import { IBankCard, ITelephony } from '@/types';

export const validateLogin = (login: string) => {
  if (!login) return 'Введите логин';

  if (login.length < 4 || login.length > 20)
    return 'Логин должен быть от 4 до 20 символов.';

  const loginRegEx = /^[A-Za-z0-9_-]{3,20}$/;
  if (!loginRegEx.test(login)) return 'Некорректный логин';
};

export const validateTelegram = (telegram: string) => {
  const tgReqex = /^@?[A-Za-z\d_]{5,32}$/;
  if (!tgReqex.test(telegram)) return 'Некорректный телеграм';
};

export const validatePhone = (phone: string) => {
  if (!phone) return 'Введите телефон';
  const phoneReqex = /^\+?[\d ()-]{8,20}$/;
  if (!phoneReqex.test(phone)) return 'Некорректный телефон';
};

export const validateNumbers = (data: string): boolean => {
  const numberReqex = /^\d+$/;
  if (!numberReqex.test(data)) return true;
  return false;
};

export const validatePassword = (password: string) => {
  if (!password) return 'Введите пароль';
  if (password.length < 6 || password.length > 30) {
    return 'Пароль должен быть не меньше 6 и не больше 30 символов';
  }
};

export const validateTelephony = (telephony: ITelephony[]) => {
  let error: string | null = null;
  for (let i = 0; i < telephony.length; i++) {
    const t = telephony[i];
    if (t.name && t.name.length > 40) {
      if (telephony.length > 1) {
        error = `Слишком длинное название ${i + 1} кампании`;
      } else {
        error = 'Слишком длинное название';
      }

      break;
    }
  }
  return error;
};

export const validateBankCards = (cards: IBankCard[]) => {
  let error: string | null = null;
  if (!cards.length) return;

  const cardNumberReqEx = /^[0-9\s]{12,20}$/;
  for (let i = 0; i < cards.length; i++) {
    const card = cards[i];
    if (card.bank === 'none') {
      error = `Укажите банк ${cards.length > 1 ? `в ${i + 1} карте` : ''}`;
      break;
    }
    if (card.bank === 'custom' && !card.customBank?.trimEnd().trimStart()) {
      error = `Укажите название банка ${
        cards.length > 1 ? `в ${i + 1} карте` : ''
      }`;
      break;
    }
    if (!card.number) {
      error = `Укажите номер ${
        cards.length > 1 ? `в ${i + 1} карте` : 'карты'
      }`;
      break;
    }
    if (!cardNumberReqEx.test(card.number.trim())) {
      error = `Некорректный номер ${
        cards.length > 1 ? `в ${i + 1} карте` : 'карты'
      }`;
      break;
    }
    if (!card.fio) {
      error = `Укажите ФИО ${cards.length > 1 ? `в ${i + 1} карте` : 'карты'}`;
      break;
    }
  }

  return error;
};

export const compareDates = (start: string, end: string) => {
  const startDateArr = start.split('/');
  const s = new Date(
    parseInt(startDateArr[0]),
    parseInt(startDateArr[1]) - 1,
    parseInt(startDateArr[2])
  );

  const endDateArr = end.split('/');
  const e = new Date(
    parseInt(endDateArr[0]),
    parseInt(endDateArr[1]) - 1,
    parseInt(endDateArr[2]),
    23,
    59,
    59
  );

  if (s.getTime() > e.getTime()) return false;
  return true;
};
export const checkDatesDiff = (start: string, end: string, days: number) => {
  const startDateArr = start.split('/');
  const s = new Date(
    parseInt(startDateArr[0]),
    parseInt(startDateArr[1]) - 1,
    parseInt(startDateArr[2])
  );

  const endDateArr = end.split('/');
  const e = new Date(
    parseInt(endDateArr[0]),
    parseInt(endDateArr[1]) - 1,
    parseInt(endDateArr[2]),
    23,
    59,
    59
  );

  const diff = Math.abs(e.getTime() - s.getTime());

  if (Math.ceil(diff / (1000 * 3600 * 24)) > days) return false;
  return true;
};
