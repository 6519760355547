import { NavLink } from 'react-router-dom';
import {
  NewsIcon,
  DiamondIcon,
  ReportMoneyIcon,
  LifebuoyIcon,
  UserCircleIcon,
  WalletIcon,
  Logo,
  UsersIcon,
  PlugIcon,
} from '@/components';
import PlusIcon from '@/components/Icons/PlusIcon';

import { useAppSelector } from '@/hooks/useRedux';
import { app } from '@/redux/features/appSlice';
import { routing } from '@/constants';

import './MainDrawer.css';

type DrawerLinkProps = {
  text: string;
  to: string;
  type?: 'link';
  icon: JSX.Element;
};

export const MainDrawer = () => {
  const { mainDrawerIsOpen } = useAppSelector(app);

  const DrawerLink: React.FC<DrawerLinkProps> = ({ text, to, icon, type }) => {
    if (type === 'link') {
      return mainDrawerIsOpen ? (
        <a href={to} rel="noreferrer" target="_blank">
          {icon}
          <span>{text}</span>
        </a>
      ) : (
        <div className="tooltip tooltip-right" data-tip={text}>
          <a href={to} rel="noreferrer" target="_blank">
            {icon}
          </a>
        </div>
      );
    }

    return mainDrawerIsOpen ? (
      <NavLink to={to}>
        {icon}
        <span>{text}</span>
      </NavLink>
    ) : (
      <div className="tooltip tooltip-right" data-tip={text}>
        <NavLink to={to}>{icon}</NavLink>
      </div>
    );
  };

  return (
    <div
      className={`hidden lg:block main-drawer bg-base-100  theme-border ${
        mainDrawerIsOpen ? 'lg:w-[270px]' : 'lg:w-[70px]'
      }`}
    >
      <div className="logo-wrapper theme-border cursor-pointer">
        <div
          className="flex justify-center h-full items-center gap-3"
          onClick={() =>
            (window.location.href = `/${routing.CABINET}/${routing.LEADS}`)
          }
        >
          <div className="logo ">
            <Logo />
          </div>
          {mainDrawerIsOpen && (
            <h1 className="font-bold text-lg brand-color brand-name">
              TECHLEAD CPA
              <span className="block font-normal text-base">private</span>
            </h1>
          )}
        </div>
      </div>
      <ul
        className={`drawer-menu mt-5 gap-2 ${
          mainDrawerIsOpen ? 'px-5' : 'px-1'
        }`}
      >
        <li>
          <DrawerLink
            to={routing.LEADS}
            text="Лиды"
            icon={<ReportMoneyIcon />}
          />
        </li>
        <li>
          <DrawerLink
            to={routing.ADD_LEAD}
            text="Добавить лид"
            icon={<PlusIcon />}
          />
        </li>

        <li>
          <DrawerLink
            to={routing.OFFERS}
            text="Офферы"
            icon={<DiamondIcon />}
          />
        </li>

        <li>
          <DrawerLink
            to={routing.FINANCES}
            text="Финансы"
            icon={<WalletIcon />}
          />
        </li>

        <li>
          <DrawerLink to={routing.NEWS} text="Новости" icon={<NewsIcon />} />
        </li>

        <li>
          <DrawerLink
            to={routing.REFERRALS}
            text="Рефералы"
            icon={<UsersIcon />}
          />
        </li>

        <li>
          <DrawerLink
            to={routing.INTEGRATION}
            text="Интеграции"
            icon={<PlugIcon />}
          />
        </li>

        <li>
          <DrawerLink
            to={routing.PROFILE}
            text="Профиль"
            icon={<UserCircleIcon />}
          />
        </li>

        <li>
          <DrawerLink
            to={process.env.REACT_APP_TG_SUPPORT_LINK!}
            text="Поддержка"
            icon={<LifebuoyIcon />}
            type="link"
          />
        </li>
      </ul>
    </div>
  );
};
