import { IIncomesByDate } from '@/types';

type Props = {
  data: IIncomesByDate[];
};

export const ProfitTableByDates: React.FC<Props> = ({ data }) => {
  const total = data.reduce(
    (acc, v) => {
      acc.totalLeads += parseInt(v.totalLeads);
      acc.leads += parseInt(v.leads);
      acc.incomes += parseInt(v.incomes);
      return acc;
    },
    {
      totalLeads: 0,
      leads: 0,
      incomes: 0,
    }
  );

  return (
    <div className='overflow-x-auto'>
      <table className='table w-full table-compact'>
        <thead>
          <tr>
            <th>Дата</th>
            <th>Всего лидов</th>
            <th>Подтвержд. лидов</th>
            <th>Доход</th>
          </tr>
        </thead>
        <tbody>
          <tr className='hover'>
            <td className='font-medium'>Всего:</td>
            <td className='font-medium'>{total.totalLeads}</td>
            <td className='font-medium'>{total.leads}</td>
            <td className='font-medium'>{total.incomes} К</td>
          </tr>
          {data.map((item) => (
            <tr key={item.date} className='hover'>
              <td>{item.date}</td>
              <td>{item.totalLeads}</td>
              <td>{item.leads}</td>
              <td>{item.incomes} К</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
