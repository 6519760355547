import { rootReducer, setupStore } from '@/redux/store';
import { OutputData } from '@editorjs/editorjs';

export interface IWindow extends Window {
  ym?: any;
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export interface IAppState {
  theme: 'light' | 'dark';
  activeStatsTab: keyof IWebmasterStatsFromPeriods;
  mainDrawerIsOpen: boolean;
}

export interface IBalance {
  hold: number;
  balance: number;
  totalEarned: number;
}

export enum TelephonyType {
  PRIVATE = 'private',
  COMPANY = 'company',
}
export interface ITelephony {
  name: string;
  number: string;
  type: TelephonyType;
}

export interface IBankCard {
  bank: string;
  number: string;
  fio: string;
  customBank?: string;
}

export enum ReferrerStatusType {
  BEGINNER = 'beginner',
  ADVANCED = 'advanced',
  MASTER = 'master',
}

export interface ICurator {
  login: string;
  name: string;
  workTelegram: string | null;
  workPhone: string | null;
}

export interface IWebmaster {
  id: number;
  login: string;
  roles: [string];
  telephony: ITelephony[];
  bankCards: IBankCard[];
  isBanned: boolean;
  createdAt: Date;
  updatedAt: Date;
  balance: IBalance;
  workPhone: string | null;
  workTelegram: string | null;
  referrerStatus: ReferrerStatusType;
  curatorId: number;
  curator: ICurator;
  legalInformation: null | LegalInformation;
}

export interface LegalInformation {
  id: number;
  name: string;
  bankBic: string;
  INN: string;
  checkingAccount: string;
  address: string;
  status: LegalInformationStatus;
}

export enum LegalInformationStatus {
  CONFIRMED = 'confirmed',
  REJECTED = 'rejected',
  MODERATION = 'moderation',
}

export interface IWebmasterState
  extends Pick<
    IWebmaster,
    'bankCards' | 'telephony' | 'workPhone' | 'workTelegram'
  > {
  id: null | number;
  login: null | string;
  isAuth: boolean;
  createdAt: Date | null;
  balance: IBalance | null;
  referrerStatus: ReferrerStatusType | null;
  curator: ICurator | null;
  apiKey: null | string;
  legalInformation: null | LegalInformation;
}

export interface INavigationState {
  newsOffset: number;
  newsLimit: number;
  newsTotal: number | null;
  offersOffset: number;
  currentNewsPage: number;
}

export interface IAccessToken {
  accessToken: string;
}

export interface ILoginData {
  login: string;
  password: string;
}

export interface IErrorResponse {
  data: { message: string };
  status?: number;
}

export interface IEditorCore {
  destroy(): Promise<void>;

  clear(): Promise<void>;

  save(): Promise<OutputData>;

  render(data: OutputData): Promise<void>;
}

export interface INews {
  id: number;
  title: string;
  visible: boolean;
  createdAt: Date;
  updatedAt: Date;
  content: string;
}

export interface INewsPrewiew extends Omit<INews, 'content'> {}

export interface INewsResponse {
  news: INewsPrewiew[];
  total: number;
  offset: number;
  limit: number;
}

export interface ICity {
  id: number;
  name: string;
}

export interface IOfferCategory {
  id: number;
  name: string;
  img: string;
}

export interface IOffersResponse {
  total: number;
  totalWithParams: number | null;
  offset: null | number;
  limit: null | number;
  categoryId: null | number;
  cityId: null | number;
  status: null | string;
  offers: IOffer[];
}

export interface IOffer {
  id: number;
  name: string;
  leadPrice: null | number;
  companyLeadPrice: null | number;
  isPaused: boolean;
  cityId: number;
  categoryId: number;
  createdAt: Date;
  updatedAt: Date;
  city: ICity;
  category: IOfferCategory;
}

export interface IOfferWithContent extends IOffer {
  content: string;
}

export enum LeadStatus {
  LEAD = 'lead',
  IN_WORK = 'in_work',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export interface ILead {
  amoId: string;
  status: LeadStatus;
  webmasterId: number | null;
  offerId: number | null;
  cityId: number | null;
  categoryId: number | null;
  sum: number | null;
  date: string | Date | null;
  comment: string | null;
  address: string | null;
  trackingPhone: string;
  hiddenLeadPhone: string;
  cityName: string | null;
  categoryName: string | null;
  data: JSON | null;
  createdAt: Date;
}

export interface IAddLeadManually {
  leadState: string;
  phone: string;
  address?: string;
  comment?: string;
  cityId: number;
  categoryId: number;
  campaign: string;
}

export interface ILeadsResponse {
  total: number;
  totalWithParams: number | null;
  offset: null | number;
  limit: null | number;
  categoryId: null | number;
  cityId: null | number;
  status: LeadStatus | null;
  sum: number | null;
  leads: ILead[];
}

export interface ILeadsState {
  startDate: string | null;
  endDate: string | null;
}

export interface IFinancesState {
  startDate: string | null;
  endDate: string | null;
  paymentModalIsOpen: boolean;
}

export interface IReferralsState {
  startDate: string | null;
  endDate: string | null;
  progressModalIsOpen: boolean;
  incomesTotal: number | null;
  refsTotal: number | null;
  refsEarnedTotal: number | null;
  progressMax: number;
}

export enum MoneyMovementType {
  PAYMENT = 'payment',
  PAYOUT_PER_LEAD = 'payoutPerLead',
  REFERRAL_INCOME = 'referralIncome',
}

export enum MoneyMovementStatus {
  COMPLETED = 'completed',
  IN_PROCESS = 'inProcess',
  CANCELED = 'canceled',
}

export interface IMoneyMovementRecord {
  id: number;
  type: MoneyMovementType;
  description: string;
  status: MoneyMovementStatus;
  sum: number;
  webmasterId: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IMoneyMovementResponse {
  records: IMoneyMovementRecord[];
  total: number;
  totalWithParams: number | null;
  earnedFromPeriod: number | null;
  offset: null | number;
  limit: null | number;
}

//======STATS========//

export interface IDayStatsData {
  hour: string;
  profit: number;
}

export interface IWeekStatsData {
  day: string;
  profit: number;
}

export interface IMonthStatsData {
  day: string;
  profit: number;
}

export interface IWebmasterStatsFromPeriods {
  today: {
    stat: IDayStatsData[];
    total: number;
  };
  yesterday: {
    stat: IDayStatsData[];
    total: number;
  };
  week: {
    stat: IWeekStatsData[];
    total: number;
  };
  month: {
    stat: IMonthStatsData[];
    total: number;
  };
  lastMonth: {
    stat: IMonthStatsData[];
    total: number;
  };
}

export interface IStatsResponse {
  totalEarned: number;
}

export enum LossReasonText {
  NOT_VALID = 'Невалид',
  INSOLVENT_PERSON = 'Неплатёжеспособное лицо',
  LEAD_CANCEL = 'Отмена лида',
  NOT_CONFIRM = 'Не подтверждён',
  NOT_DIALING = 'Пропала связь с клиентом',
  SPAM = 'Спам',
  NOT_ACTIVE_OFFER = 'Неактив. оффер',
  NOT_ACTIVE_GEO = 'Неактив. ГЕО',
  CALL_CHECK = 'Проверка связи',
}

export interface IRegisterData {
  login: string;
  password: string;
  workPhone: string;
  workTelegram: string;
  refId?: number;
  curatorId?: number;
  clientId?: string;
  visitId?: string;
}

export interface IRefVisit {
  date: string;
  visits: string;
  unique: string;
  registrations: string;
  success: string;
  reject: string;
  waiting: string;
}

interface IIncomesData {
  incomes: string;
  leads: string;
  totalLeads: string;
}

export interface IIncomesByDate extends IIncomesData {
  date: string;
}

export interface IIncomesByLogin extends IIncomesData {
  login: string;
}

export interface IRefsIncomesResponse {
  byDate: IIncomesByDate[];
  byLogin: IIncomesByLogin[];
}

export interface IRefsStatResponse {
  refsTotal: number;
  refsEarnedTotal: number;
  incomesTotal: number;
}
