import { useState, useEffect } from "react";
import { NotificationManager as notify } from "react-notifications";

import { useAppSelector } from "@/hooks/useRedux";
import { useGetRefIncomesQuery } from "@/redux/api/referralsAPI";
import { referrals } from "@/redux/features/referralsSlice";
import { IErrorResponse } from "@/types";

import "./ProfitStats.css";
import {
  ProfitByDatesMobCollapse,
  ProfitByLoginsMobCollapse,
  ProfitTableByDates,
  ProfitTableByLogins,
} from "@/components";

export const ProfitStats = () => {
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();

  const { startDate, endDate } = useAppSelector(referrals);

  const { data, error, isLoading, isFetching } = useGetRefIncomesQuery({
    start,
    end,
  });

  useEffect(() => {
    if (startDate && endDate) {
      setStart(startDate);
      setEnd(endDate);
    } else {
      setStart(undefined);
      setEnd(undefined);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    if (data) return notify.error(data.message);
    return notify.error("Что-то пошло не так");
  }, [error]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isFetching || isLoading) {
      setLoading(true);
    } else if (!isFetching && !isLoading) {
      timeout = setTimeout(() => setLoading(false), 200);
    }
    return () => clearTimeout(timeout);
  }, [isFetching, isLoading]);

  return (
    <div className="flex gap-5 flex-col xl:flex-row">
      <div
        className={`theme-border profit-list ${
          loading || !data?.byDate.length ? "empty" : ""
        }`}
      >
        {loading ? (
          <span className="btn btn-square loading"></span>
        ) : data?.byDate.length ? (
          <>
            <div className="w-full hidden md:block">
              <ProfitTableByDates data={data.byDate} />
            </div>

            <div className="block md:hidden rounded-md overflow-hidden shadow t-border">
              {data.byDate &&
                data.byDate.map((el, idx) => {
                  return <ProfitByDatesMobCollapse key={idx} data={el} />;
                })}
            </div>
          </>
        ) : (
          <span className="text-2xl no-data-text font-thin">
            Пока что данных нет
          </span>
        )}
      </div>
      <div
        className={`theme-border profit-list ${
          loading || !data?.byLogin.length ? "empty" : ""
        }`}
      >
        {loading ? (
          <span className="btn btn-square loading"></span>
        ) : data?.byLogin.length ? (
          <>
            <div className="w-full hidden md:block">
              <ProfitTableByLogins data={data.byLogin} />
            </div>

            <div className="block md:hidden rounded-md overflow-hidden shadow t-border">
              {data.byLogin &&
                data.byLogin.map((el, idx) => {
                  return <ProfitByLoginsMobCollapse key={idx} data={el} />;
                })}
            </div>
          </>
        ) : (
          <span className="text-2xl no-data-text font-thin">
            Пока что данных нет
          </span>
        )}
      </div>
    </div>
  );
};
