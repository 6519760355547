import { IDayStatsData } from "@/types";
import { areaChartBaseConfig } from "@/utils";
import { Area } from "@ant-design/plots";

type Props = {
  data: IDayStatsData[];
};

export const DayChart: React.FC<Props> = ({ data }) => {
  const settings = {
    data,
    xField: "hour",
    yField: "profit",

    xAxis: {
      range: [0, 1],
      tickCount: data.length,
    },
  };
  const config = { ...areaChartBaseConfig, ...settings };
  return <Area {...config} />;
};
