import { useEffect, useState } from 'react';
import { NotificationManager as notify } from 'react-notifications';
import { useChangePasswordMutation } from '@/redux/api/webmasterAPI';
import { IErrorResponse } from '@/types';
import { PasswordInput } from '@/components';

export const ResetPasswordBlock = () => {
  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [isPasswordChanged, PasswordChanged] = useState(false);
  const [isNewPasswordChanged, setNewPasswordChanged] = useState(false);

  const [changePassword, { error, isSuccess }] = useChangePasswordMutation();

  const passwordBtnSubmit = isPasswordChanged || isNewPasswordChanged;

  const passwordChangeHandler = (value: string) => {
    setPassword(value);
    PasswordChanged(true);
  };

  const newPasswordChangeHandler = (value: string) => {
    setNewPassword(value);
    setNewPasswordChanged(true);
  };

  const changePasswordHandler = () => {
    if (!password) return notify.error('Введите текущий пароль');
    if (!newPassword) return notify.error('Введите новый пароль');
    changePassword({
      password,
      newPassword,
    });
  };

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    notify.error(data.message);
  }, [error]);

  useEffect(() => {
    if (!isSuccess) return;
    notify.success('Пароль успешно изменен!');
    setPassword('');
    setNewPassword('');
    PasswordChanged(false);
    setNewPasswordChanged(false);
  }, [isSuccess]);

  return (
    <div className="w-full lg:max-w-sm">
      <div className="text-xl font-medium mb-3">Смена пароля</div>
      <label className="label">
        <span className="label-text">Текущий пароль</span>
      </label>
      <div className="form-control lg:w-2/3 flex-row gap-5 items-center relative">
        <PasswordInput
          password={password}
          changeHandler={passwordChangeHandler}
          size="sm"
        />
      </div>
      <label className="label mt-3">
        <span className="label-text">Новый пароль</span>
      </label>
      <div className="form-control lg:w-2/3 flex-row gap-5 items-center relative">
        <PasswordInput
          password={newPassword}
          changeHandler={newPasswordChangeHandler}
          size="sm"
        />
      </div>
      <button
        onClick={changePasswordHandler}
        className={`btn btn-primary btn-sm mt-5 ${
          passwordBtnSubmit ? '' : 'btn-disabled'
        }`}
      >
        Сменить
      </button>
    </div>
  );
};
