import { useEffect, useState } from 'react';
import { NotificationManager as notify } from 'react-notifications';

import { useAppSelector } from '@/hooks/useRedux';

import { webmaster } from '@/redux/features/webmasterSlice';
import { validateNumbers } from '@/utils';
import { LegalInformationStatusBadge } from '@/components';
import { useUpdateLegalInformationMutation } from '@/redux/api/legalInformationAPI';
import { IErrorResponse, LegalInformationStatus } from '@/types';

const { CONFIRMED, REJECTED, MODERATION } = LegalInformationStatus;

const legalEmailSupport = process.env.REACT_APP_LEGAL_SUPPORT_EMAIL;

export const ProfileLegalInfo = () => {
  const profile = useAppSelector(webmaster);
  const { legalInformation } = profile;

  const [update, { error, isSuccess }] = useUpdateLegalInformationMutation();

  const [name, setName] = useState(legalInformation?.name || '');
  const [INN, setINN] = useState(legalInformation?.INN || '');
  const [checkingAccount, setCheckingAccount] = useState(
    legalInformation?.checkingAccount || ''
  );
  const [bankBic, setBankBic] = useState(legalInformation?.bankBic || '');
  const [address, setAddress] = useState(legalInformation?.address || '');
  const [isBtnDisabled, setBtnDisabled] = useState(true);

  const saveInfoHandler = () => {
    if (!name) return notify.error('Введите Наименование/ФИО');
    if (!INN) return notify.error('Введите ИНН');
    const INNError = validateNumbers(INN);
    if (INNError) return notify.error('Некорректный ИНН');

    if (!checkingAccount) return notify.error('Введите Расчетный счет');
    const checkingAccountError = validateNumbers(checkingAccount);
    if (checkingAccountError) {
      return notify.error('Некорректный Расчетный счет');
    }

    if (!bankBic) return notify.error('Введите БИК банка');
    const bankBICError = validateNumbers(bankBic);
    if (bankBICError) return notify.error('Некорректный БИК банка');

    if (!address) return notify.error('Введите Адрес регистрации');

    update({ name, INN, checkingAccount, bankBic, address });
    setBtnDisabled(true);
  };

  const subText =
    legalInformation?.status === undefined
      ? 'Для активации аккаунта заполните и отправьте данные на модерацию.'
      : legalInformation?.status === MODERATION
      ? 'Данные на модерации, пожалуйста ожидайте'
      : legalInformation?.status === CONFIRMED
      ? 'Данные подтверждены. При изменении - они снова отправятся на проверку'
      : legalInformation?.status === REJECTED
      ? 'Данные не прошли проверку'
      : '';

  useEffect(() => {
    if (!isSuccess) return;
    return notify.success('Сохранено');
  }, [isSuccess]);

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    if (data) return notify.error(data.message);
    return notify.error('Что-то пошло не так');
  }, [error]);

  useEffect(() => {
    if (name && INN && checkingAccount && address && bankBic) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [name, INN, checkingAccount, address, bankBic]);

  useEffect(() => {
    setBtnDisabled(true);
  }, []);

  return (
    <div>
      <div className="text-xl font-medium my-3">
        Юридическая информация и статус
      </div>
      <div className="uppercase mt-6 font-medium">
        ваш статус :{' '}
        <LegalInformationStatusBadge status={legalInformation?.status} />
      </div>
      <div className="text mt-1">{subText}</div>
      <div className="text mb-4 mt-1">
        Запросить весь документооборот -{' '}
        <a className="link link-primary" href={`mailto:${legalEmailSupport}`}>
          {legalEmailSupport}
        </a>
      </div>
      <div className="flex gap-8 w-full flex-col md:flex-row">
        <div className="w-full md:w-1/2">
          <label className="label">
            <span className="label-text">Наименование/ФИО</span>
          </label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="input pr-8 input-bordered w-full input-sm "
          />
          <label className="label">
            <span className="label-text">ИНН</span>
          </label>
          <input
            value={INN}
            onChange={(e) => setINN(e.target.value)}
            className="input pr-8 input-bordered w-full input-sm "
          />
          <label className="label">
            <span className="label-text">Pасчетный счет</span>
          </label>
          <input
            value={checkingAccount}
            onChange={(e) => setCheckingAccount(e.target.value)}
            className="input pr-8 input-bordered w-full input-sm "
          />
        </div>

        <div className="w-full md:w-1/2">
          <label className="label">
            <span className="label-text">БИК банка</span>
          </label>
          <input
            value={bankBic}
            onChange={(e) => setBankBic(e.target.value)}
            className="input pr-8 input-bordered w-full input-sm "
          />
          <label className="label">
            <span className="label-text">Адрес регистрации</span>
          </label>
          <textarea
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="textarea pb-0 textarea-sm h-[100px] resize-none textarea-bordered pr-8  w-full "
          />
        </div>
      </div>
      <div>
        <button
          disabled={isBtnDisabled}
          className="btn btn-sm btn-success mt-5"
          onClick={saveInfoHandler}
        >
          Отправить
        </button>
      </div>
    </div>
  );
};
