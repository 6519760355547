export const areaChartBaseConfig = {
  smooth: true,
  isPercent: false,
  tooltip: {
    formatter: (t: any) => {
      return { name: 'Заработано', value: t.profit + ' К' };
    },
  },
  point: {
    color: '#570df8',
    size: 2,
    style: {},
  },
  yAxis: {
    grid: {
      line: {
        style: { stroke: '#e4e4e4', lineWidth: 1 },
      },
    },
  },
  line: {
    color: '#570df8',
    size: 1,
  },

  areaStyle: () => {
    return {
      fill: '#570df8',
    };
  },
  height: 200,
};
