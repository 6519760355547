import { tokenLSKey } from '@/constants';
import { LegalInformation } from '@/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { patchLegalInformation } from '../features/webmasterSlice';

export const legalInformationAPI = createApi({
  reducerPath: 'legalInformationAPI',

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/legal-information/',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set('authorization', `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    updateLegalInformation: builder.mutation<
      LegalInformation,
      Omit<LegalInformation, 'status' | 'id'>
    >({
      query: (body) => ({
        url: '/update',

        method: 'PATCH',
        body,
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(patchLegalInformation(data));
        } catch (error) {}
      },
    }),
  }),
});

export const { useUpdateLegalInformationMutation } = legalInformationAPI;
