import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { app, setActiveStatsTab } from '@/redux/features/appSlice';
import { IWebmasterStatsFromPeriods } from '@/types';

import './Tabs.css';

const statsKeys: { [key: string]: string } = {
  today: 'Сегодня',
  yesterday: 'Вчера',
  week: 'Неделя',
  month: 'Месяц',
  lastMonth: 'Прошлый месяц',
};

type Props = {
  tabs: [keyof IWebmasterStatsFromPeriods, number][];
};

export const Tabs: React.FC<Props> = ({ tabs }) => {
  const dispatch = useAppDispatch();
  const { activeStatsTab: active } = useAppSelector(app);
  const setTab = (tab: keyof IWebmasterStatsFromPeriods) =>
    dispatch(setActiveStatsTab(tab));

  return (
    <div className='tabulation'>
      {tabs.map((tab) => {
        return (
          <div
            key={tab[0]}
            onClick={() => setTab(tab[0])}
            className={`tabulation-item ${active === tab[0] ? 'active' : ''}`}
          >
            <div>{statsKeys[tab[0]]}</div>
            <div className='font-medium lg:text-lg'>{tab[1]} К</div>
          </div>
        );
      })}
    </div>
  );
};
