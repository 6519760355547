import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";

import { useGetMeQuery } from "@/redux/api/webmasterAPI";
import { PageLoader } from "@/components";
import { useAppSelector } from "@/hooks/useRedux";
import { webmaster } from "@/redux/features/webmasterSlice";
import { routing, tokenLSKey } from "@/constants";

export const AuthLayout = () => {
  const { isAuth } = useAppSelector(webmaster);
  const { isLoading, isFetching, error, data } = useGetMeQuery(null);
  const isBanned = data?.isBanned;
  const [loading, setloading] = useState(false);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isFetching || isLoading) {
      setloading(true);
    } else if (!isFetching && !isLoading) {
      timeout = setTimeout(() => setloading(false), 200);
    }
    return () => clearTimeout(timeout);
  }, [isFetching, isLoading]);

  useEffect(() => {
    if (!error) return;
    localStorage.removeItem(tokenLSKey);
    window.location.href = "/" + routing.LOGIN;
  }, [error]);

  useEffect(() => {
    if (isBanned) {
      localStorage.removeItem(tokenLSKey);
      window.location.href = "/" + routing.BANNED;
    }
  }, [isBanned]);

  if (loading || !isAuth) return <PageLoader />;
  return <Outlet />;
};
