import { useAppSelector } from "@/hooks/useRedux";
import { webmaster } from "@/redux/features/webmasterSlice";
import { useState } from "react";

type Props = {
  valueHandler: (phone: string) => void;
  noCategory?: boolean;
};

export const SelectPhone: React.FC<Props> = ({ valueHandler, noCategory }) => {
  const { telephony } = useAppSelector(webmaster);
  const [selected, setSelected] = useState<string>("all");

  const changeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelected(event.target.value);
    valueHandler(event.target.value);
  };

  return (
    <>
      <label className="label">
        <span className="label-text">Рекл. кампании</span>
      </label>
      <select
        className="select select-sm select-bordered w-full lg:max-w-xs "
        value={selected}
        onChange={changeSelect}
      >
        {noCategory ? (
          <option value="all" disabled>
            Выберите кампанию
          </option>
        ) : (
          <option value="all">Все кампании</option>
        )}
        {telephony.map((t) => (
          <option value={t.number} key={t.number}>
            {t.name ? t.name : t.number}
          </option>
        ))}
        <option value="p_api">Добавление по API (Частное лицо)</option>
        <option value="c_api">Добавление по API (Компания)</option>
        <option value="p_manually">Ручное добавление (Частное лицо)</option>
        <option value="c_manually">Ручное добавление (Компания)</option>
      </select>
    </>
  );
};
