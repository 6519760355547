import { RootState } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FormData {
  telegram: string;
  code: string;
  newPassword: string;
  newPasswordRepeat: string;
}

const initialState: {
  step: number;

  formData: FormData;
} = {
  step: 1,

  formData: {
    telegram: "",
    code: "",
    newPassword: "",
    newPasswordRepeat: "",
  },
};

export const passwordRecoverySlice = createSlice({
  name: "passwordRecovery",
  initialState,

  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },

    setFormData: (state, action: PayloadAction<FormData>) => {
      state.formData = action.payload;
    },

    reset: (state) => {
      state.step = 1;
      state.formData = {
        telegram: "",
        code: "",
        newPassword: "",
        newPasswordRepeat: "",
      };
    },
  },
});

export default passwordRecoverySlice.reducer;

export const { setStep, setFormData, reset } = passwordRecoverySlice.actions;

export const passwordRecovery = (state: RootState) => state.passwordRecovery;
