import useOnClickOutside from "@/hooks/useOnClickOutside";
import { MutableRefObject, useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
  BurgerIcon,
  DiamondIcon,
  LifebuoyIcon,
  NewsIcon,
  PlugIcon,
  ReportMoneyIcon,
  TelegramIcon,
  UserCircleIcon,
  UsersIcon,
  WalletIcon,
} from "@/components";
import { routing } from "@/constants";
import PlusIcon from "../Icons/PlusIcon";

export const MobileMenu = () => {
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [open, setOpen] = useState(false);
  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div className="navbar-start lg:hidden">
      <div ref={ref} className={`dropdown  ${open ? "dropdown-open" : ""}`}>
        <label
          tabIndex={0}
          className="btn btn-ghost "
          onClick={() => setOpen(!open)}
        >
          <BurgerIcon />
        </label>
        <ul
          tabIndex={0}
          className="menu menu-compact dropdown-content dropdown-open mt-3 p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li onClick={() => setOpen(false)}>
            <Link to={routing.LEADS}>
              <ReportMoneyIcon />
              <span>Лиды</span>
            </Link>
          </li>
          <li onClick={() => setOpen(false)}>
            <Link to={routing.ADD_LEAD}>
              <PlusIcon />
              <span>Добавить лид</span>
            </Link>
          </li>

          <li onClick={() => setOpen(false)}>
            <Link to={routing.OFFERS}>
              <DiamondIcon />
              <span>Офферы</span>
            </Link>
          </li>

          <li onClick={() => setOpen(false)}>
            <Link to={routing.FINANCES}>
              <WalletIcon />
              <span>Финансы</span>
            </Link>
          </li>

          <li onClick={() => setOpen(false)}>
            <Link to={routing.NEWS}>
              <NewsIcon />
              <span>Новости</span>
            </Link>
          </li>

          <li onClick={() => setOpen(false)}>
            <Link to={routing.REFERRALS}>
              <UsersIcon />
              <span>Рефералы</span>
            </Link>
          </li>

          <li onClick={() => setOpen(false)}>
            <Link to={routing.INTEGRATION}>
              <PlugIcon />
              <span>Интеграции</span>
            </Link>
          </li>

          <li onClick={() => setOpen(false)}>
            <Link to={routing.PROFILE}>
              <UserCircleIcon />
              <span>Профиль</span>
            </Link>
          </li>

          <li onClick={() => setOpen(false)}>
            <a
              href={process.env.REACT_APP_TG_SUPPORT_LINK}
              rel="noreferrer"
              target="_blank"
            >
              <LifebuoyIcon />
              <span>Поддержка</span>
            </a>
          </li>
          <li onClick={() => setOpen(false)}>
            <a
              className="social-link"
              href={process.env.REACT_APP_TG_CHANNEL}
              rel="noreferrer"
              target="_blank"
            >
              <TelegramIcon />
              <span className="link-primary">Телеграм канал</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
