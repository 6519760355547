import { useAppSelector } from '@/hooks/useRedux';
import { app } from '@/redux/features/appSlice';
import { Outlet } from 'react-router-dom';
import { TopNavBar, Footer, MainDrawer, SecondNavBar } from '../';

import './Layout.css';
export const Layout = () => {
  const { mainDrawerIsOpen } = useAppSelector(app);

  return (
    <>
      <header
        className={`bg-base-100 ${
          mainDrawerIsOpen ? 'lg:ml-[270px]' : 'lg:ml-[70px]'
        }`}
      >
        <TopNavBar />
        <SecondNavBar />
      </header>
      <div
        className={`wrapper flex ${
          mainDrawerIsOpen ? 'lg:ml-[270px]' : 'lg:ml-[70px]'
        }`}
      >
        <aside className='sidebar'>
          <MainDrawer />
        </aside>
        <div className='main-content w-full'>
          <main className='p-3 md:p-10 page'>
            <Outlet />
          </main>
          <footer>
            <Footer />
          </footer>
        </div>
      </div>
    </>
  );
};
