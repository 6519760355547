
import { Link } from 'react-router-dom';

import { useTitle } from '@/hooks';
import { LoginForm, Logo } from '@/components';
import { routing } from '@/constants';

import './Login.css';

export const Login = () => {
  useTitle('Вход для вебмастеров');


  return (
    <div className='page login-page bg-base-200 flex items-center justify-center'>
      <div className='login-form-wrapper p-3 md:p-0'>
        <div className='bg-base-100 theme-border shadow rounded-md sm:p-10 pt-4 p-5 relative'>
          <div className='login-logo mx-auto'>
            <Logo />
          </div>

          <div className="lg:text-xl brand-color font-bold text-center mb-4">
            TECHLEAD CPA

          </div>
          <p className='text-lg mb-1'>Добро пожаловать</p>
          <p className='sub-text'>
            Для входа введите данные от вашего аккаунта
          </p>

          <LoginForm />
        </div>

        <Link
          className="mt-3 link link-primary text-center mx-auto w-full inline-block"

          to={`/${routing.PASSWORD_RECOVERY}`}
        >
          Восстановление пароля
        </Link>
      </div>
    </div>
  );
};
