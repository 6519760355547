import {
  IWebmaster,
  IWebmasterState,
  LegalInformation,
  RootState,
} from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IWebmasterState = {
  id: null,
  login: null,
  isAuth: false,
  bankCards: [],
  telephony: [],
  createdAt: null,
  balance: null,
  workTelegram: null,
  workPhone: null,
  referrerStatus: null,
  curator: null,
  apiKey: null,
  legalInformation: null,
};

export const webmasterSlice = createSlice({
  initialState,
  name: 'webmaster',
  reducers: {
    logout: () => initialState,
    setWebmaster: (state, action: PayloadAction<IWebmaster>) => {
      if (!action.payload.isBanned) {
        state.id = action.payload.id;
        state.login = action.payload.login;
        state.isAuth = true;
        state.bankCards = action.payload.bankCards;
        state.createdAt = action.payload.createdAt;
        state.balance = action.payload.balance;
        state.telephony = action.payload.telephony;
        state.workTelegram = action.payload.workTelegram;
        state.workPhone = action.payload.workPhone;
        state.referrerStatus = action.payload.referrerStatus;
        state.curator = action.payload.curator;
        state.legalInformation = action.payload.legalInformation;
      }
    },
    removeFromBalance: (state, action: PayloadAction<number>) => {
      if (state.balance) {
        state.balance.balance -= action.payload;
      }
    },
    setApiKey: (state, action: PayloadAction<string>) => {
      state.apiKey = action.payload;
    },
    removeApiKey: (state) => {
      state.apiKey = null;
    },
    patchLegalInformation: (state, action: PayloadAction<LegalInformation>) => {
      state.legalInformation = action.payload;
    },
  },
});

export default webmasterSlice.reducer;

export const {
  logout,
  setWebmaster,
  removeFromBalance,
  setApiKey,
  removeApiKey,
  patchLegalInformation,
} = webmasterSlice.actions;
export const webmaster = (state: RootState) => state.webmaster;
