import useOnClickOutside from '@/hooks/useOnClickOutside';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
  referrals,
  toggleProgressModal,
} from '@/redux/features/referralsSlice';
import { MutableRefObject, useRef } from 'react';

export const ReferrerProgressModal = () => {
  const dispatch = useAppDispatch();
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);
  useOnClickOutside(ref, () => isOpen && dispatch(toggleProgressModal()));

  const {
    progressModalIsOpen: isOpen,
    refsEarnedTotal,
    progressMax,
  } = useAppSelector(referrals);

  const progressBarClass =
    refsEarnedTotal && refsEarnedTotal >= progressMax
      ? ' progress-success'
      : refsEarnedTotal && refsEarnedTotal >= progressMax / 2
      ? 'progress-accent'
      : 'progress-info';

  return (
    <div className={`modal ${isOpen ? 'modal-open' : ''}`}>
      <div ref={ref} className='modal-box w-11/12 max-w-5xl'>
        <h3 className='font-bold text-lg md:text-2xl'>
          Ваш реферальный процент и статус
        </h3>
        <p className='py-4'>
          Чем больше заработают ваши рефералы, тем выше будет Ваш статус и
          реферальный процент.
        </p>
        {refsEarnedTotal !== null && refsEarnedTotal >= progressMax ? (
          <div className='flex  justify-between w-full lg:w-2/3'>
            <span>Вы достигли максимального статуса: </span>
            <span className='font-medium'> Увереный лев</span>
          </div>
        ) : refsEarnedTotal !== null && refsEarnedTotal >= progressMax / 2 ? (
          <div className='flex justify-between flex-col xl:flex-row w-full lg:w-2/3'>
            <span>
              До <span className='font-medium'>Уверенного льва </span> Вашим
              рефералам осталось заработать:
            </span>
            <span className='font-medium'>
              {progressMax - refsEarnedTotal} К
            </span>
          </div>
        ) : (
          refsEarnedTotal !== null &&
          refsEarnedTotal < progressMax / 2 && (
            <>
              <div className='flex justify-between flex-col xl:flex-row w-full lg:w-2/3'>
                <span>
                  До <span className='font-medium'>Льва на минималках </span>
                  Вашим рефералам осталось заработать:
                </span>
                <span className='font-medium'>
                  {progressMax / 2 - refsEarnedTotal} К
                </span>
              </div>
              <div className='flex justify-between flex-col xl:flex-row w-full lg:w-2/3'>
                <span>
                  До <span className='font-medium'>Уверенного льва </span> Вашим
                  рефералам осталось заработать:
                </span>
                <span className='font-medium'>
                  {progressMax - refsEarnedTotal} К
                </span>
              </div>
            </>
          )
        )}
        <h3 className='font-bold text-lg mb-1 pt-5'>Ваш прогресс:</h3>
        <progress
          className={`progress ${progressBarClass} mb-1 h-3`}
          value={refsEarnedTotal || 0}
          max={progressMax}
        ></progress>
        <div className='flex justify-between'>
          <span>
            <div className='badge badge-info'>3%</div>
            <div className='font-medium'>Львенок</div>
            <div>до 500 тыс К</div>
          </span>
          <span className='text-center ml-4'>
            <div className='badge badge-accent'>5%</div>
            <div className='font-medium'>Лев на минималках</div>
            <div>больше 500 тыс К</div>
          </span>
          <span className='text-right'>
            <div className='badge badge-success'>7%</div>
            <div className='font-medium'>Уверенный лев</div>
            <div>больше 1 млн К</div>
          </span>
        </div>
        <div className='modal-action'>
          <button
            className='btn btn-success btn-sm lowercase'
            onClick={() => dispatch(toggleProgressModal())}
          >
            Понятно
          </button>
        </div>
      </div>
    </div>
  );
};
