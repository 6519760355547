type Props = {
  total: number;
  step: number;
  offset: number;
  callback: (page: number) => void;
};

export const Pagination: React.FC<Props> = ({
  total,
  step,
  offset,
  callback,
}) => {
  const totalPages = Math.ceil(total / step);
  const current =
    offset === 0 ? 1 : totalPages - Math.ceil((total - offset) / step) + 1;
  const pagesArr = Array.from({ length: totalPages }, (_, i) => i + 1);

  const clickHandler = (i: number) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
    if (current === i) return;
    callback(i);
  };

  return (
    <>
      {totalPages > 1 && (
        <div className="btn-group">
          <>
            {pagesArr[current - 4] && (
              <button
                className="btn  border-0 shadow bg-base color-base"
                onClick={() => clickHandler(current - 3)}
              >
                {current - 3}
              </button>
            )}
            {pagesArr[current - 3] && (
              <button
                className="btn  border-0 shadow bg-base color-base"
                onClick={() => clickHandler(current - 2)}
              >
                {current - 2}
              </button>
            )}
            {pagesArr[current - 2] && (
              <button
                className="btn  border-0 shadow bg-base color-base"
                onClick={() => clickHandler(current - 1)}
              >
                {current - 1}
              </button>
            )}

            <button className="btn  border-0 shadow btn-primary">
              {current}
            </button>

            {pagesArr[current] && (
              <button
                className="btn  border-0 shadow bg-base color-base"
                onClick={() => clickHandler(current + 1)}
              >
                {current + 1}
              </button>
            )}
            {pagesArr[current + 1] && (
              <button
                className="btn  border-0 shadow bg-base color-base"
                onClick={() => clickHandler(current + 2)}
              >
                {current + 2}
              </button>
            )}
            {pagesArr[current + 2] && (
              <button
                className="btn  border-0 shadow bg-base color-base"
                onClick={() => clickHandler(current + 3)}
              >
                {current + 3}
              </button>
            )}
          </>
        </div>
      )}
    </>
  );
};
