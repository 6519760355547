import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { tokenLSKey } from "@/constants";
import { IRefsIncomesResponse, IRefsStatResponse } from "@/types";
import { setStatsInfo } from "../features/referralsSlice";

export const referralsAPI = createApi({
  reducerPath: "referralsAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/referrals",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getRefIncomes: builder.query<
      IRefsIncomesResponse,
      { start: string | undefined; end: string | undefined }
    >({
      query: (params) => ({
        url: "periods",
        method: "GET",
        params: {
          start: params?.start,
          end: params?.end,
        },
      }),
    }),
    getRefStats: builder.query<IRefsStatResponse, null>({
      query: () => ({
        url: "stats",
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setStatsInfo(data));
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetRefIncomesQuery, useGetRefStatsQuery } = referralsAPI;
