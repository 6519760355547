import { IIncomesByLogin } from '@/types';
import { FC, useState } from 'react';

type Props = {
  data: IIncomesByLogin;
};

export const ProfitByLoginsMobCollapse: FC<Props> = ({ data }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div
      className={`mob-collapse bg-base-100 theme-border last:border-b-0 collapse ${
        isOpen ? 'collapse-open' : ''
      }`}
    >
      <div
        className='collapse-title cursor-pointer min-h-[auto] py-2 px-2 min-[360px]:px-5'
        onClick={() => setIsOpen((p) => !p)}
      >
        <div className='flex gap-2 items-center flex-wrap justify-between'>
          <div className='mr-auto text-sm'>{data.login}</div>
          {data.incomes} К
        </div>
      </div>

      <div className='collapse-content px-2 min-[360px]:px-5'>
        <div>
          <hr className={`divider h-[1px] ${isOpen && 'mt-0'}`} />
          <div className='flex flex-col items-start gap-1'>
            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Реферал:</span>
              </label>
              <div>{data.login}</div>
            </div>
            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Всего лидов:</span>
              </label>
              <div>{data.totalLeads}</div>
            </div>
            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>
                  Подтвержд. лидов:
                </span>
              </label>
              <div>{data.leads}</div>
            </div>
            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Доход:</span>
              </label>
              <div>{data.incomes} К</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
