import { Link } from 'react-router-dom';

import { UserIcon, UsersIcon } from '@/components';
import { routing } from '@/constants';
import { IOffer } from '@/types';

import './OfferItem.css';

type Props = {
  offer: IOffer;
};

export const OfferItem: React.FC<Props> = ({ offer }) => {
  return (
    <div className='offer-item flex lg:justify-between lg:flex-row flex-col p-5 bg-base-100   rounded-md my-5 shadow'>
      <div className='flex flex-col md:flex-row items-center'>
        <Link to={`/${routing.CABINET}/${routing.OFFERS}/${offer.id}`}>
          <div
            className='rounded-md h-40 w-40 border  md:mr-5 theme-border offer-img'
            style={{
              backgroundImage: `url(${process.env.REACT_APP_API_URL}${offer.category.img})`,
            }}
          ></div>
        </Link>
        <div>
          <Link
            to={`/${routing.CABINET}/${routing.OFFERS}/${offer.id}`}
            className='title text-lg block text-center lg:text-2xl md:text-left font-medium capitalize'
          >
            {offer.name}
          </Link>
          <div className='stat-title mt-1 hidden md:block'>
            <div>ID: {offer.id}</div>
          </div>

          <div className='flex gap-3 mt-3 justify-center items-center md:justify-start flex-col md:flex-row'>
            <span className='badge md:badge-lg badge-primary px-5'>
              {offer.city.name.toUpperCase()}
            </span>
            {offer.isPaused && (
              <span className='uppercase badge md:badge-lg badge-warning px-5'>
                Остановлен
              </span>
            )}
          </div>
        </div>
      </div>
      <div className='flex flex-col justify-between mt-3 '>
        <div className='lg:pr-5 flex flex-col items-center md:block'>
          <p className='text-lg pl-1 mb-2'>Цена за заявку:</p>
          <div className='flex justify-between gap-5  w-60  '>
            <div className='flex items-center gap-2'>
              <span
                className='rounded-full bg-primary p-1'
                style={{ scale: '0.8' }}
              >
                <UserIcon />
              </span>
              <span>Частное лицо </span>
            </div>
            <span className='font-medium text-xl pt-0.5 '>
              {offer.leadPrice} К
            </span>
          </div>
          {offer.companyLeadPrice && (
            <div className='flex justify-between gap-5 w-60'>
              <div className='flex items-center gap-2'>
                <span
                  className='rounded-full bg-primary p-1'
                  style={{ scale: '0.8' }}
                >
                  <UsersIcon />
                </span>
                <span>Компания </span>
              </div>
              <span className='font-medium text-xl pt-0.5'>
                {offer.companyLeadPrice} К
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
