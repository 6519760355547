import { QuestionIcon } from "@/components";
import { useAppDispatch } from "@/hooks/useRedux";
import { toggleProgressModal } from "@/redux/features/referralsSlice";
import { ReferrerStatusType } from "@/types";
import { getReferrerStatusText } from "@/utils";

type Props = {
  status: ReferrerStatusType | null;
};

export const ReferrerStatus: React.FC<Props> = ({ status }) => {
  const dispatch = useAppDispatch();
  const statusText = status ? getReferrerStatusText(status) : "";
  return (
    <div className="theme-border rounded-md px-6 py-4 bg-base-100 shadow">
      <div className="flex justify-between items-center gap-10 ">
        <span className="flex items-center gap-1">
          <span>Ваш статус:</span>
          <span
            className="question-icon mt-[2px]"
            onClick={() => dispatch(toggleProgressModal())}
          >
            <QuestionIcon />
          </span>
        </span>
        <span
          className="font-medium link-primary underline cursor-pointer"
          onClick={() => dispatch(toggleProgressModal())}
        >
          {statusText}
        </span>
      </div>
      <div className="flex justify-between items-center gap-10 mt-2">
        <span>Ваш реферальный процент:</span>
        {status === ReferrerStatusType.BEGINNER ? (
          <span className="badge badge-info">3%</span>
        ) : status === ReferrerStatusType.ADVANCED ? (
          <span className="badge badge-accent">5%</span>
        ) : status === ReferrerStatusType.MASTER ? (
          <span className="badge badge-success">7%</span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
