import { RootState } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  leadsSearchValue: string;
} = {
  leadsSearchValue: "",
};

export const searchesSlice = createSlice({
  initialState,
  name: "searches",
  reducers: {
    setLeadsSearchValue: (state, action: PayloadAction<string>) => {
      state.leadsSearchValue = action.payload;
    },
  },
});

export default searchesSlice.reducer;

export const { setLeadsSearchValue } = searchesSlice.actions;
export const searches = (state: RootState) => state.searches;
