import { useEffect, useState } from "react";
import { NotificationManager as notify } from "react-notifications";

import { useAppSelector } from "@/hooks/useRedux";
import { webmaster } from "@/redux/features/webmasterSlice";
import { IErrorResponse, ITelephony, TelephonyType } from "@/types";
import { useChangeTelephonyMutation } from "@/redux/api/webmasterAPI";
import { validateTelephony } from "@/utils";

export const TelephonyCompanies = () => {
  const { telephony } = useAppSelector(webmaster);
  const [changeTelephony, { isSuccess, error }] = useChangeTelephonyMutation();
  const [inputs, setInputs] = useState<ITelephony[]>(telephony);

  const inputsChangeHandler = (
    idx: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    let data = [...inputs];
    data[idx] = { ...data[idx], [name]: value };
    setInputs([...data]);
  };

  const saveHandler = () => {
    const error = validateTelephony(inputs);
    if (error) return notify.error(error);
    changeTelephony({ telephony: inputs });
  };

  useEffect(() => {
    if (!isSuccess) return;
    notify.success("Данные сохранены!");
  }, [isSuccess]);

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    if (data) return notify.error(data.message);
    notify.error("Что-то пошло не так");
  }, [error]);

  return (
    <div>
      <div className="text-xl font-medium my-3">Рекламные кампании</div>
      {inputs.length ? (
        inputs.map((t, i) => (
          <div className="flex items-center gap-2" key={t.number}>
            <div className="form-control w-full lg:max-w-xl">
              <label className="label mt-3 sm:mt-0">
                <span className="label-text">
                  Тип:{" "}
                  {t.type === TelephonyType.COMPANY
                    ? "Компания"
                    : t.type === TelephonyType.PRIVATE && "Частное лицо"}
                </span>
              </label>
              <div className="sm:input-group">
                <span className="my-2 sm:my-0 inline-block bg-base-300 py-1 px-2 rounded-md">
                  {t.number}
                </span>
                <input
                  onChange={(e) => inputsChangeHandler(i, e)}
                  type="text"
                  placeholder="Название кампании"
                  value={t.name}
                  className="input input-bordered input-sm w-full"
                  name="name"
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-xl p-3 no-data-text font-thin">
          У Вас нет рекламных кампаний
        </div>
      )}
      {inputs.length > 0 && (
        <div className="flex mt-5 gap-3">
          <button className="btn btn-success btn-sm" onClick={saveHandler}>
            сохранить
          </button>
        </div>
      )}
    </div>
  );
};
