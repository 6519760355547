import { ReferrerStatusType } from '@/types';

export const getReferrerStatusText = (status: ReferrerStatusType) => {
  switch (status) {
    case ReferrerStatusType.BEGINNER:
      return 'Львенок';
    case ReferrerStatusType.ADVANCED:
      return 'Лев на минималках';
    case ReferrerStatusType.MASTER:
      return 'Увереный лев';
  }
};
