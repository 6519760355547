import { MobileMenu, Balance } from "@/components";

import "./SecondNavBar.css";
import { useAppSelector } from "@/hooks/useRedux";
import { webmaster } from "@/redux/features/webmasterSlice";

export const SecondNavBar = () => {
  const { balance } = useAppSelector(webmaster);
  return (
    <div className="navbar bg-base-100 lg:hidden">
      <MobileMenu />
      <div className="navbar-end w-4/6">
        <Balance balance={balance?.balance} hold={balance?.hold} />
      </div>
    </div>
  );
};
