import { MoneyMovementStatus } from "@/types";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineFieldTime,
} from "react-icons/ai";

export const getFinancesStatusIcon = (status: MoneyMovementStatus) => {
  switch (status) {
    case MoneyMovementStatus.IN_PROCESS:
      return <AiOutlineFieldTime className="text-info" size={20} />;
    case MoneyMovementStatus.CANCELED:
      return <AiOutlineClose className="text-error" size={20} />;
    case MoneyMovementStatus.COMPLETED:
      return <AiOutlineCheck className="text-success" size={20} />;
  }
};
