import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import {
  IAccessToken,
  IBankCard,
  ILoginData,
  ITelephony,
  IWebmaster,
  IRegisterData,
} from '@/types';
import { setWebmaster } from '../features/webmasterSlice';
import { tokenLSKey } from '@/constants';

export const webmasterAPI = createApi({
  reducerPath: 'webmasterAPI',

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set('authorization', `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    login: builder.mutation<IAccessToken, ILoginData>({
      query: (credentials) => ({
        url: '/auth/webmaster/login',
        method: 'POST',
        body: credentials,
      }),
    }),

    register: builder.mutation<IAccessToken, IRegisterData>({
      query: (body) => ({
        url: '/auth/webmaster/register',
        method: 'POST',

        body,
      }),
    }),

    forgotPassword: builder.mutation<
      { request_id: string; user_id: number },
      { workTelegram: string }
    >({
      query: (body) => ({
        url: '/webmaster/forgot-password',
        method: 'POST',
        body,
      }),
    }),

    sendCode: builder.mutation<
      { message: string },
      { request_id: string; code: string }
    >({
      query: (body) => ({
        url: '/auth/code',
        method: 'POST',
        body,
      }),
    }),

    resetPassword: builder.mutation<
      IAccessToken,
      { request_id: string; user_id: number; password: string }
    >({
      query: (body) => ({
        url: '/webmaster/reset-password',
        method: 'POST',

        body,
      }),
    }),

    getMe: builder.query<IWebmaster, null>({
      query: () => ({
        url: '/webmaster/',
        method: 'GET',
      }),

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setWebmaster(data));
        } catch (error) {}
      },
    }),
    setBankCards: builder.mutation<IWebmaster, { bankCards: IBankCard[] }>({
      query: (body) => ({
        url: '/webmaster/card',
        method: 'PATCH',
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setWebmaster(data));
        } catch (error) {}
      },
    }),
    changePassword: builder.mutation<
      IWebmaster,
      { password: string; newPassword: string }
    >({
      query: (body) => ({
        url: '/webmaster/password',
        method: 'PATCH',
        body,
      }),
    }),
    changeContacts: builder.mutation<
      IWebmaster,
      { workTelegram: string | null; workPhone: string | null }
    >({
      query: (body) => ({
        url: '/webmaster/contacts',
        method: 'PATCH',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setWebmaster(data));
        } catch (error) {}
      },
    }),
    changeTelephony: builder.mutation<IWebmaster, { telephony: ITelephony[] }>({
      query: (body) => ({
        url: '/webmaster/telephony',
        method: 'PATCH',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setWebmaster(data));
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetMeQuery,
  useLoginMutation,
  useSetBankCardsMutation,
  useChangePasswordMutation,
  useChangeContactsMutation,
  useChangeTelephonyMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useSendCodeMutation,
  useResetPasswordMutation,
} = webmasterAPI;
