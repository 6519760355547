import {
  IDayStatsData,
  IMonthStatsData,
  IWebmasterStatsFromPeriods,
  IWeekStatsData,
} from "@/types";
import { DayChart, MonthChart, WeekChart } from "@/components/";

type Props = {
  data: {
    stat: IDayStatsData[] | IWeekStatsData[] | IMonthStatsData[];
    total: number;
  };
  tabKey: keyof IWebmasterStatsFromPeriods;
};

export const MoneyEarningStat: React.FC<Props> = ({ data, tabKey }) => {
  /*   if (!data.total) {
    return (
      <div className="h-[200px] flex items-center justify-center">
        <span className="text-2xl no-data-text font-thin">
          Пока что данных нет
        </span>
      </div>
    );
  } */
  if (tabKey === "yesterday" || tabKey === "today") {
    return <DayChart data={data.stat as IDayStatsData[]} />;
  }

  if (tabKey === "week") {
    return <WeekChart data={data.stat as IWeekStatsData[]} />;
  }

  if (tabKey === "month" || tabKey === "lastMonth") {
    return <MonthChart data={data.stat as IMonthStatsData[]} />;
  }
  return <div></div>;
};
