import { useEffect, useState } from 'react';
import { NotificationManager as notify } from 'react-notifications';

import { useAppSelector } from '@/hooks/useRedux';
import { useGetRefVisitsQuery } from '@/redux/api/visitsAPI';
import { referrals } from '@/redux/features/referralsSlice';
import { IErrorResponse } from '@/types';
import './RegStats.css';
import { RegsStatsMobCollapse } from '../RegsStatsMobCollapse/RegsStatsMobCollapse';

const initTotalState = {
  visits: 0,
  unique: 0,
  registrations: 0,
  success: 0,
  reject: 0,
  waiting: 0,
};

export const RegsStats = () => {
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();
  const [totals, setTotals] = useState(initTotalState);

  const { startDate, endDate } = useAppSelector(referrals);

  const { data, isLoading, isFetching, error } = useGetRefVisitsQuery({
    start,
    end,
  });

  const dataIsset = loading || !data?.length;

  useEffect(() => {
    if (startDate && endDate) {
      setStart(startDate);
      setEnd(endDate);
    } else {
      setStart(undefined);
      setEnd(undefined);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (!data || !data.length) return;
    const res = data.reduce(
      (acc, v) => {
        acc.visits += parseInt(v.visits);
        acc.unique += parseInt(v.unique);
        acc.registrations += parseInt(v.registrations);
        acc.success += parseInt(v.success);
        acc.reject += parseInt(v.reject);
        acc.waiting += parseInt(v.waiting);
        return acc;
      },
      { ...initTotalState }
    );

    setTotals(res);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    if (data) return notify.error(data.message);
    return notify.error('Что-то пошло не так');
  }, [error]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isFetching || isLoading) {
      setLoading(true);
    } else if (!isFetching && !isLoading) {
      timeout = setTimeout(() => setLoading(false), 200);
    }
    return () => clearTimeout(timeout);
  }, [isFetching, isLoading]);

  return (
    <>
      <div className={`theme-border regs-list ${dataIsset ? 'empty' : ''}`}>
        {loading ? (
          <span className='btn btn-square loading'></span>
        ) : data?.length ? (
          <>
            <div className='block md:hidden rounded-md overflow-hidden mb-5 px-2 min-[360px]:px-5'>
              <h3 className='font-bold'>Всего:</h3>
              <div className='flex items-center justify-between'>
                <label className='label p-0'>
                  <span className='label-text font-medium'>Переходы:</span>
                </label>

                <div>{totals.visits}</div>
              </div>
              <div className='flex items-center justify-between'>
                <label className='label p-0'>
                  <span className='label-text font-medium'>
                    Уник. переходы:
                  </span>
                </label>

                <div>{totals.unique}</div>
              </div>

              <div className='flex items-center justify-between'>
                <label className='label p-0'>
                  <span className='label-text font-medium'>Регистрации:</span>
                </label>

                <div>{totals.registrations}</div>
              </div>

              <div className='flex items-center justify-between'>
                <label className='label p-0'>
                  <span className='label-text font-medium'>В ожидании:</span>
                </label>

                <div>{totals.waiting}</div>
              </div>

              <div className='flex items-center justify-between'>
                <label className='label p-0'>
                  <span className='label-text font-medium'>Отклоненные:</span>
                </label>

                <div>{totals.reject}</div>
              </div>

              <div className='flex items-center justify-between'>
                <label className='label p-0'>
                  <span className='label-text font-medium'>
                    Подтвержденные:
                  </span>
                </label>

                <div>{totals.success}</div>
              </div>
            </div>

            <div className='w-full hidden md:block'>
              <div className='overflow-x-auto'>
                <table className='table w-full table-compact'>
                  <thead>
                    <tr>
                      <th>Дата</th>
                      <th>Переходы</th>
                      <th>Уник. переходы</th>
                      <th>Регистрации</th>
                      <th>В ожидании</th>
                      <th>Отклоненные</th>
                      <th>Подтвержденные</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='hover'>
                      <td className='font-medium'>Всего:</td>
                      <td className='font-medium'>{totals.visits}</td>
                      <td className='font-medium'>{totals.unique}</td>
                      <td className='font-medium'>{totals.registrations}</td>
                      <td className='font-medium'>{totals.waiting}</td>
                      <td className='font-medium'>{totals.reject}</td>
                      <td className='font-medium'>{totals.success}</td>
                    </tr>

                    {data.map((visit) => (
                      <tr key={visit.date} className='hover'>
                        <td>{visit.date}</td>
                        <td>{visit.visits}</td>
                        <td>{visit.unique}</td>
                        <td>{visit.registrations}</td>
                        <td>{visit.waiting}</td>
                        <td>{visit.reject}</td>
                        <td>{visit.success}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className='block md:hidden rounded-md overflow-hidden shadow t-border'>
              {data &&
                data.map((el, idx) => {
                  return <RegsStatsMobCollapse key={idx} data={el} />;
                })}
            </div>
          </>
        ) : (
          <span className='text-2xl no-data-text font-thin'>
            Пока что данных нет
          </span>
        )}
      </div>
    </>
  );
};
